import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Header from "../components/Header";

export default function Root() {
  return (
    <Box>
      <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
        <Header />
      </div>
      <Outlet />
      <ReactQueryDevtools />
    </Box>
  );
}
