import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../components/ProtectedPage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import React, { ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { postTrainingRecord, putTrainingRecord } from "../api";
import {
  IEnergyZone,
  IGear,
  IStartPosition,
  IStroke,
  ITeam,
  ITrainingRecord,
  ITrainingRecordVariables,
} from "../types";
import {
  getEnergyZones,
  getGears,
  getStartPositions,
  getStrokes,
  getTeams,
} from "../api";

interface ITrainingRecordProps {
  isNew: boolean;
  record?: ITrainingRecord;
}

function countSegments(input: string): number {
  // 문자열을 "/"로 나누기
  const segments = input.split("/");
  // 빈 문자열을 필터링해서 문자의 개수만 구하기
  const nonEmptySegments = segments.filter((segment) => segment !== "");
  return nonEmptySegments.length;
}

export default function TrainingRecord({
  isNew,
  record,
}: // teams,
// strokes,
// energyZones,
// gears,
// startPositions,
ITrainingRecordProps) {
  const [resultValue, setResultValue] = React.useState("");
  const handleResultChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResultValue(event.target.value);
  };

  const { register, watch, handleSubmit } = useForm<ITrainingRecordVariables>();
  // console.log(watch());
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: isNew ? postTrainingRecord : putTrainingRecord,
    onSuccess: (data: ITrainingRecord) => {
      toast({
        status: "success",
        title: isNew ? "Training record created" : "Training record updated",
        position: "bottom-right",
      });
      navigate(`/training_records/${data.id}`);
    },
  });
  const onSubmit = (data: ITrainingRecordVariables) => {
    // gears 는 number[] 타입이나, 최초 undefined type 으로 인식되어
    // 아무것도 선택을 안하면 false 로 처리되어 아래와 같이 workaround 처리함.
    if (!data.gears) {
      data.gears = [];
    }

    // console.log(isNew, data, record);
    if (!isNew) data.id = record?.id as any;

    mutation.mutate(data);
  };

  const handleCancelClick = () => {
    navigate(isNew ? "/" : `/training_records/${record?.id}`);
  };

  useEffect(() => {
    if (!isNew) setResultValue(record?.result as any);
  }, []);

  const { data: teams, isLoading: isTeamsLoading } = useQuery<ITeam[]>({
    queryKey: ["teams"],
    queryFn: getTeams,
  });
  const { data: strokes, isLoading: isStrokesLoading } = useQuery<IStroke[]>({
    queryKey: ["strokes"],
    queryFn: getStrokes,
  });
  const { data: energyZones, isLoading: isEnergyZonesLoading } = useQuery<
    IEnergyZone[]
  >({
    queryKey: ["energyZones"],
    queryFn: getEnergyZones,
  });
  const { data: gears, isLoading: isGearsLoading } = useQuery<IGear[]>({
    queryKey: ["gears"],
    queryFn: getGears,
  });
  const { data: startPositions, isLoading: isStartPositionsLoading } = useQuery<
    IStartPosition[]
  >({
    queryKey: ["startPositions"],
    queryFn: getStartPositions,
  });

  return (
    <ProtectedPage>
      <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
        {isEnergyZonesLoading ||
        isGearsLoading ||
        isStartPositionsLoading ||
        isStrokesLoading ||
        isTeamsLoading ? (
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        ) : (
          <Container>
            <Heading textAlign={"center"}>
              {isNew ? "Create a new record" : "Update a record"}
            </Heading>
            <VStack
              spacing={5}
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              mt={5}
            >
              <Flex width={"100%"} gap={2}>
                <FormControl isRequired>
                  <FormLabel>Date</FormLabel>
                  <Input
                    defaultValue={record?.date}
                    {...register("date", { required: true })}
                    type="date"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Teams</FormLabel>
                  <Select
                    defaultValue={record?.team.id}
                    {...register("team")}
                    placeholder="Choose a team"
                  >
                    {teams?.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  defaultValue={record?.description}
                  {...register("description")}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Stroke</FormLabel>
                <Stack>
                  <Select
                    defaultValue={record?.stroke.id}
                    {...register("stroke", { required: true })}
                    placeholder="Choose a type of stroke"
                  >
                    {strokes?.map((stroke) => (
                      <option key={stroke.id} value={stroke.id}>
                        {stroke.name} - {stroke.description}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </FormControl>

              <FormControl>
                <FormLabel>Options</FormLabel>
                <CheckboxGroup>
                  <Stack
                    spacing={[1, 5]}
                    direction={["column", "row"]}
                    gap="10"
                  >
                    <Checkbox
                      defaultChecked={record?.is_test}
                      {...register("is_test")}
                    >
                      Test Set
                    </Checkbox>
                    <Checkbox
                      defaultChecked={record?.kick_only}
                      {...register("kick_only")}
                    >
                      Kick Only
                    </Checkbox>
                    <Checkbox
                      defaultChecked={record?.drag_suit}
                      {...register("drag_suit")}
                    >
                      Drag Suit
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              <Flex width={"100%"} gap="2">
                <FormControl isRequired>
                  <FormLabel>Start Position</FormLabel>
                  <Stack>
                    <Select
                      defaultValue={
                        record?.start_position
                          ? record?.start_position.id
                          : startPositions?.at(0)?.id
                      }
                      {...register("start_position", { required: true })}
                      placeholder="Choose a start position"
                    >
                      {startPositions?.map((startPosition) => (
                        <option key={startPosition.id} value={startPosition.id}>
                          {startPosition.name}
                        </option>
                      ))}
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Energy Zone</FormLabel>
                  <Select
                    defaultValue={record?.energy_zone?.id}
                    {...register("energy_zone", { required: true })}
                    placeholder="Choose an energy zone"
                  >
                    {energyZones?.map((energyZone) => (
                      <option key={energyZone.id} value={energyZone.id}>
                        {energyZone.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <HStack>
                <FormControl isRequired>
                  <FormLabel>Distance</FormLabel>
                  <Select
                    defaultValue={record?.swim_distance ?? 50}
                    {...register("swim_distance", { required: true })}
                  >
                    <option value={25}>25m</option>
                    <option value={50}>50m</option>
                    <option value={100}>100m</option>
                    <option value={200}>200m</option>
                    <option value={400}>400m</option>
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Count</FormLabel>
                  <NumberInput
                    defaultValue={record?.interval_count ?? 0}
                    min={1}
                  >
                    <NumberInputField
                      {...register("interval_count", { required: true })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Time</FormLabel>
                  <NumberInput
                    defaultValue={record?.interval_time ?? 0}
                    min={0}
                  >
                    <NumberInputField
                      {...register("interval_time", { required: true })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </HStack>

              <FormControl>
                <FormLabel>Result</FormLabel>
                <InputGroup>
                  <InputLeftAddon>{countSegments(resultValue)}</InputLeftAddon>
                  <Input
                    {...register("result")}
                    type="text"
                    defaultValue={record?.result}
                    onChange={handleResultChange}
                    placeholder="aa/bb/cc/dd ..."
                  />
                </InputGroup>
              </FormControl>

              <Grid templateColumns={"1fr 1fr"} w="100%" gap={4}>
                <FormControl>
                  <FormLabel>Stroke Rate (SR)</FormLabel>
                  <NumberInput defaultValue={record?.stroke_rate ?? 0} min={0}>
                    <NumberInputField {...register("stroke_rate")} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormHelperText>Stroke counts in 60 seconds</FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Heart Rate (HR)</FormLabel>
                  <NumberInput defaultValue={record?.heart_rate ?? 0} min={0}>
                    <NumberInputField {...register("heart_rate")} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormHelperText>Heart beats in 60 seconds</FormHelperText>
                </FormControl>
              </Grid>

              <FormControl>
                <FormLabel>Gears</FormLabel>
                <Grid templateColumns={"1fr 1fr 1fr"}>
                  {gears?.map((gear) => (
                    <Box key={gear.id}>
                      <Checkbox
                        defaultChecked={record?.gears.some(
                          (item) => item.id === gear.id
                        )}
                        {...register("gears")}
                        value={gear.id}
                      >
                        {gear.name}
                      </Checkbox>
                    </Box>
                  ))}
                </Grid>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Length of pool</FormLabel>
                <Select
                  defaultValue={record?.pool_length ?? 25}
                  {...register("pool_length", { required: true })}
                >
                  <option value={25}>25m</option>
                  <option value={50}>50m</option>
                </Select>
              </FormControl>

              {mutation.isError ? (
                <Text color="red.500">Something went wrong</Text>
              ) : null}

              <Flex width={"100%"}>
                <Button
                  onClick={handleCancelClick}
                  isLoading={mutation.isPending}
                  size="lg"
                  width={"40%"}
                >
                  Cancel
                </Button>
                <Spacer />
                <Button
                  type="submit"
                  isLoading={mutation.isPending}
                  colorScheme="red"
                  size="lg"
                  width={"40%"}
                >
                  Done
                </Button>
              </Flex>
            </VStack>
          </Container>
        )}
      </Box>
    </ProtectedPage>
  );
}
