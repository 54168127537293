import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { BiChat, BiLike, BiShare } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";

import Markdown from "react-markdown";

import { deleteTrainingRecord } from "../api";
import { ITrainingRecord, ITrainingRecordsProps } from "../types";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatNumber,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  convertSecondsListToMinutesArray,
  convertSecondsToMinutes,
} from "../helper";
import { useRef, useState } from "react";
import useUser from "../lib/useUser";

export default function TraingRecordsByCard(data: ITrainingRecordsProps) {
  const { user, userLoading, isLoggedIn } = useUser();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const handleConfirmCancel = () => {
    console.log(currentRecord);
    onClose();
  };
  const handleConfirmDelete = () => {
    if (currentRecord) deleteTrainingRecord(currentRecord.id as any);
    onClose();
    //todo: delete result 에 따른 처리
    queryClient.resetQueries();
    navigate(`/training_records?display=card`);
  };

  const [currentRecord, setCurrentRecord] = useState<ITrainingRecord>();
  const handleDeleteClick = (record: ITrainingRecord) => {
    setCurrentRecord(record);
    onOpen();
  };

  return (
    <>
      <Stack alignContent={"center"} alignItems={"center"} gap="5" mt="5">
        {data.records?.map((record) => (
          <Card width="90%" m="5" p={"2"} variant={"elevated"} key={record.id}>
            <CardHeader>
              <Flex>
                <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                  <Avatar
                    name={record?.owner.name}
                    src={record?.owner.avatar}
                  />
                  <Box>
                    <Heading size="sm">{record?.owner.name}</Heading>
                    <Text>{record?.owner.username}</Text>
                  </Box>
                </Flex>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="See menu"
                    icon={<BsThreeDotsVertical />}
                  />
                  <MenuList>
                    <Link to={`/training_records/${record.id}/edit/`}>
                      <MenuItem isDisabled={record?.owner.id !== user?.id}>
                        Edit
                      </MenuItem>
                    </Link>
                    <MenuItem
                      isDisabled={record?.owner.id !== user?.id}
                      onClick={() => {
                        handleDeleteClick(record);
                      }}
                      color={"red"}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="md">
                    {record?.date}
                    <Badge variant="solid" colorScheme="green" ml="1">
                      {record?.team.name}
                    </Badge>
                    {record?.is_test ? (
                      <Badge variant="solid" colorScheme="red" ml="1">
                        TEST
                      </Badge>
                    ) : null}
                  </Heading>
                  <HStack pt="2">
                    {record?.energy_zone ? (
                      <Tag colorScheme="orange">{record?.energy_zone.name}</Tag>
                    ) : null}
                    {record?.stroke ? (
                      <Tag colorScheme="pink">
                        <TagLabel>{record?.stroke.name}</TagLabel>
                      </Tag>
                    ) : null}
                    {record?.kick_only ? (
                      <Tag colorScheme="teal">Kick</Tag>
                    ) : null}
                    {record?.drag_suit ? (
                      <Tag colorScheme="purple">Drag Suit</Tag>
                    ) : null}
                    {record?.start_position ? (
                      <Tag colorScheme="cyan">
                        <TagLabel>{record?.start_position.name}</TagLabel>
                      </Tag>
                    ) : null}
                  </HStack>
                  <HStack pt="2">
                    <Text fontSize="lg">
                      {record?.swim_distance} x {record?.interval_count}
                      {record?.interval_time ? " on " : ""}
                      {record?.interval_time
                        ? convertSecondsToMinutes(record?.interval_time)
                        : ""}
                    </Text>
                  </HStack>
                </Box>

                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Description
                  </Heading>
                  <Box pt="2">
                    <Markdown>{record?.description}</Markdown>
                  </Box>
                </Box>

                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Result
                  </Heading>
                  <Text pt="2" fontSize="md">
                    {record
                      ? convertSecondsListToMinutesArray(record.result)
                      : ""}
                  </Text>
                  <Text fontSize="xs">
                    SR {record?.stroke_rate}, HR {record?.heart_rate}
                  </Text>
                </Box>

                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Average
                  </Heading>
                  <Stat>
                    <StatNumber>
                      {convertSecondsToMinutes(record?.avg!)}
                    </StatNumber>
                    <StatHelpText>
                      Min {convertSecondsToMinutes(record?.min!)} - Max{" "}
                      {convertSecondsToMinutes(record?.max!)}
                    </StatHelpText>
                  </Stat>
                </Box>
                <Box>
                  <Heading size="sm" textTransform="uppercase">
                    Gears
                  </Heading>
                  <HStack pt="2">
                    {record?.gears.map((gear) => (
                      <Tag>{gear.name}</Tag>
                    ))}
                  </HStack>
                </Box>
              </Stack>
            </CardBody>

            {/* <CardFooter
          justify="space-between"
          flexWrap="wrap"
          sx={{
            "& > button": {
              minW: "136px",
            },
          }}
        >
          <Button flex="1" variant="ghost" leftIcon={<BiLike />}>
            Like
          </Button>
          <Button flex="1" variant="ghost" leftIcon={<BiChat />}>
            Comment
          </Button>
          <Button flex="1" variant="ghost" leftIcon={<BiShare />}>
            Share
          </Button>
        </CardFooter> */}
          </Card>
        ))}
      </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Record
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleConfirmCancel}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
