import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useColorModeValue,
  Badge,
  Stack,
} from "@chakra-ui/react";
import { ITrainingRecordsProps } from "../types";
import {
  convertSecondsListToMinutesArray,
  convertSecondsToMinutes,
} from "../helper";

export default function TrainingRecordsByTable(data: ITrainingRecordsProps) {
  const navigate = useNavigate();

  const handleRecordClick = (recordId: number) => {
    navigate(`/training_records/${recordId}`);
  };
  const bg = useColorModeValue("gray.200", "gray.700");

  return (
    <TableContainer px={"2"} py={"4"}>
      <Table size="sm">
        <TableCaption>{data.records?.length} training records</TableCaption>
        <Thead>
          <Tr>
            <Th>Owner</Th>
            <Th>Date</Th>
            <Th>Options</Th>
            <Th>Stroke</Th>
            <Th>E-Zone</Th>
            <Th>Interval</Th>
            <Th isNumeric>Time</Th>
            <Th isNumeric>Avg</Th>
            <Th isNumeric>Min</Th>
            <Th isNumeric>Max</Th>
            <Th>Result</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.records?.map((record) => (
            <Tr
              key={record.id}
              _hover={{ bgColor: `${bg}` }}
              onClick={() => {
                handleRecordClick(record.id);
              }}
            >
              <Td>{record.owner?.name}</Td>
              <Td>{record.date}</Td>
              <Td>
                <Stack direction="row">
                  {record.is_test ? (
                    <Badge variant="subtle" colorScheme="red">
                      Test
                    </Badge>
                  ) : null}
                  {record.kick_only ? (
                    <Badge variant="subtle" colorScheme="green">
                      Kick
                    </Badge>
                  ) : null}
                  {record.drag_suit ? (
                    <Badge variant="subtle" colorScheme="purple">
                      Drag
                    </Badge>
                  ) : null}
                </Stack>
              </Td>
              <Td>{record.stroke?.name}</Td>
              <Td>{record.energy_zone?.name}</Td>
              <Td>
                {record.swim_distance} x {record.interval_count}
              </Td>
              <Td isNumeric>{convertSecondsToMinutes(record.interval_time)}</Td>
              <Td isNumeric>{convertSecondsToMinutes(record.avg)}</Td>
              <Td isNumeric>{convertSecondsToMinutes(record.min)}</Td>
              <Td isNumeric>{convertSecondsToMinutes(record.max)}</Td>
              <Td>{convertSecondsListToMinutesArray(record.result)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
