import {
  FaMoon,
  FaRegAddressCard,
  FaSun,
  FaUser,
  FaUserAstronaut,
  FaUsers,
} from "react-icons/fa";
import { LuTable } from "react-icons/lu";
import {
  Avatar,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  ToastId,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import LoginModal from "./LoginModal";
import useUser from "../lib/useUser";
import { logOut } from "../api";

export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const { toggleColorMode } = useColorMode();
  const logoColor = useColorModeValue("red.500", "red.200");
  const bgColor = useColorModeValue("white", "#1a202c");
  const Icon = useColorModeValue(FaMoon, FaSun);
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: logOut,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["me"] });
      // toast({
      //   title: `See you ${user?.username}!`,
      //   status: "success",
      //   duration: 3000,
      // });
      // }
      navigate(`/`);
    },
  });
  const onLogOut = async () => {
    mutation.mutate();
  };
  const [isOneUser, setIsOneTarget] = useState(false);
  const [isCardDisplayMode, setIsCardDisplayMode] = useState(true);
  const toggleDisplayMode = () => {
    navigate(
      `/training_records?display=${!isCardDisplayMode ? "card" : "table"}${
        isOneUser ? "&userId=" + user?.id : ""
      }`
    );
    setIsCardDisplayMode((prev) => !prev);
  };

  const toggleTarget = () => {
    navigate(
      `/training_records?display=${isCardDisplayMode ? "card" : "table"}${
        !isOneUser ? "&userId=" + user?.id : ""
      }`
    );
    setIsOneTarget((prev) => !prev);
  };

  return (
    <Stack
      bgColor={bgColor}
      justifyContent={"space-between"}
      alignItems="center"
      py={2}
      px={6}
      direction={{
        sm: "column",
        md: "row",
      }}
      spacing={{
        sm: 2,
        md: 0,
      }}
      borderBottomWidth={1}
    >
      <Link to={"/"}>
        <HStack color={logoColor}>
          <FaUserAstronaut size={"50"} />
          <Text ml={"1"} fontSize={"lg"}>
            욕망의 스위머즈 1.0
          </Text>
        </HStack>
      </Link>
      <HStack>
        {isLoggedIn && (
          <IconButton
            onClick={toggleDisplayMode}
            aria-label="display mode"
            variant={"ghost"}
            size="lg"
            icon={isCardDisplayMode ? <LuTable /> : <FaRegAddressCard />}
          />
        )}
        {isLoggedIn && (
          <IconButton
            onClick={toggleTarget}
            aria-label="target"
            variant={"ghost"}
            size="lg"
            icon={isOneUser ? <FaUsers /> : <FaUser />}
          />
        )}
      </HStack>
      <HStack spacing={2}>
        <Text fontSize={"sm"}>당신의 욕망을 채워주세요</Text>
        <IconButton
          onClick={toggleColorMode}
          variant={"ghost"}
          aria-label="Toggle dark mode"
          icon={<Icon />}
        />
        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button onClick={onLoginOpen}>Log in</Button>
            </>
          ) : (
            <Menu>
              <MenuButton>
                <Avatar name={user?.name} src={user?.avatar} size={"md"} />
              </MenuButton>
              <MenuList>
                <Link to="/training_records/post">
                  <MenuItem>Post a Record</MenuItem>
                </Link>
                <Link
                  to={
                    process.env.NODE_ENV === "development"
                      ? "http://127.0.0.1:8000/admin"
                      : "https://myswimrecord.onrender.com/admin"
                  }
                >
                  <MenuItem>Admin</MenuItem>
                </Link>
                <MenuItem onClick={onLogOut}>Log out</MenuItem>
              </MenuList>
            </Menu>
          )
        ) : null}
      </HStack>
      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
    </Stack>
  );
}
