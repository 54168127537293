import Cookie from "js-cookie";
import axios, { AxiosError } from "axios";
import { QueryFunctionContext } from "@tanstack/react-query";
import { ITrainingRecordVariables } from "./types";

export interface IGetLogInTokenVariables {
  username: string;
  password: string;
}

export interface IGetLogInTokenSuccess {
  token: string;
}

export interface IGetLogInTokenError {
  non_field_errors: string;
}

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1"
      : "https://myswimrecord.onrender.com/api/v1",
  withCredentials: true,
});

export const getMe = () =>
  instance
    .get("users/me", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const logOut = () => {
  Cookie.remove("login-token");
  return instance
    .post("users/log-out", null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};

export const getLogInToken = ({
  username,
  password,
}: IGetLogInTokenVariables) =>
  instance
    .post(
      "users/get-token",
      { username, password },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getTrainingRecords = (userId: number, page: number) => {
  if (userId > 0) {
    return instance
      .get(`training_records/?userid=${userId}&page=${page}`, {
        headers: { Authorization: `Token ${Cookie.get("login-token")}` },
      })
      .then((response) => response.data);
  } else {
    return instance
      .get(`training_records/?page=${page}`, {
        headers: { Authorization: `Token ${Cookie.get("login-token")}` },
      })
      .then((response) => response.data);
  }
};

export const getTrainingRecord = ({ queryKey }: QueryFunctionContext) => {
  const [_, recordId] = queryKey;
  return instance
    .get(`training_records/${recordId}`, {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);
};

export const getTeams = () =>
  instance
    .get("teams", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const getStrokes = () =>
  instance
    .get("strokes", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const getGears = () =>
  instance
    .get("gears", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const getEnergyZones = () =>
  instance
    .get("energy_zones", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const getStartPositions = () =>
  instance
    .get("start_positions", {
      headers: { Authorization: `Token ${Cookie.get("login-token")}` },
    })
    .then((response) => response.data);

export const postTrainingRecord = (variables: ITrainingRecordVariables) =>
  instance
    .post("training_records/", variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        Authorization: `Token ${Cookie.get("login-token")}`,
      },
    })
    .then((response) => response.data);

export const putTrainingRecord = (variables: ITrainingRecordVariables) => {
  return instance
    .put(`training_records/${variables.id}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        Authorization: `Token ${Cookie.get("login-token")}`,
      },
    })
    .then((response) => response.data);
};

export const deleteTrainingRecord = (recordId: string) => {
  return instance
    .delete(`training_records/${recordId}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        Authorization: `Token ${Cookie.get("login-token")}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log("error catched:", error);
    });
};
