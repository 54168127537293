export function convertSecondsToMinutes(value: number): string {
  const numberValue = Number(value);
  const minutes = Math.trunc(numberValue / 60);
  const remainingValue = numberValue % 60;
  const seconds = Math.trunc(remainingValue);
  const milliseconds = Math.trunc(
    Number((remainingValue - seconds).toFixed(2)) * 100
  );

  return (
    `${minutes > 0 ? minutes + "'" : ""}` +
    `${seconds.toString().padStart(2, "0")}` +
    `${"." + milliseconds.toString().padStart(2, "0")}`
  );
}

export function convertSecondsListToMinutesArray(input: string): string {
  return input.split("/").map(__convertStringSecondsToMinutes).join("/");
}

function __convertStringSecondsToMinutes(value: string): string {
  if (__isNumeric(value)) return convertSecondsToMinutes(Number(value));
  return value;
}

function __isNumeric(value: string): boolean {
  const num = Number(value);
  return !isNaN(num) && !isNaN(parseFloat(value));
}
