import { useQuery } from "@tanstack/react-query";
import { ITrainingRecord } from "../types";
import { getTrainingRecords } from "../api";
import TrainingRecordsByTable from "../components/TrainingRecordsByTable";
import { useLocation } from "react-router-dom";
import TraingRecordsByCard from "../components/TrainingRecordsByCard";
import {
  Box,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";

interface TrainingRecordListProps {
  userId?: number;
  page?: number;
}

export default function TrainingRecords({
  userId = 0,
  page = 1,
}: TrainingRecordListProps) {
  const { isLoading, data } = useQuery<ITrainingRecord[]>({
    queryKey: ["training_records", userId, page],
    queryFn: () => getTrainingRecords(userId, page),
  });

  const location = useLocation();
  const display = new URLSearchParams(location.search).get("display");
  const isTable = display === "table";

  return isLoading ? (
    isTable ? (
      <Stack m="5">
        {Array.from({ length: 50 }, (v, i) => (
          <Skeleton key={i} height="20px" />
        ))}
      </Stack>
    ) : (
      <Stack alignContent={"center"} alignItems={"center"} gap="5" mt="5">
        {Array.from({ length: 10 }, (v, i) => (
          <Box key={i} width="90%" m="5" p="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="12" />
            <SkeletonText mt="8" noOfLines={7} spacing="6" skeletonHeight="3" />
          </Box>
        ))}
      </Stack>
    )
  ) : isTable ? (
    <TrainingRecordsByTable records={data} />
  ) : (
    <TraingRecordsByCard records={data} />
  );
}
