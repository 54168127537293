import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Center, CircularProgress } from "@chakra-ui/react";
import { getTrainingRecord } from "../api";
import { ITrainingRecord } from "../types";
import TrainingRecord from "../components/TrainingRecord";

export default function EditTrainingRecord() {
  const { recordId } = useParams();
  console.log("recordId:", recordId);
  const { data: record, isLoading: isRecordLoading } =
    useQuery<ITrainingRecord>({
      queryKey: ["training_records", recordId],
      queryFn: getTrainingRecord,
    });

  return isRecordLoading ? (
    <Center height="100vh" alignContent={"center"} alignItems={"center"}>
      <CircularProgress isIndeterminate color={"#d0e"} size={"120px"} />
    </Center>
  ) : (
    <TrainingRecord isNew={false} record={record} />
  );
}
