import { createBrowserRouter } from "react-router-dom";
import Root from "./routes/Root";
import NotFound from "./routes/NotFound";
import TraingRecordDetail from "./routes/TrainingRecordDetail";
import PostTrainingRecord from "./routes/PostTrainingRecord";
import Home from "./routes/Home";
import EditTrainingRecord from "./routes/EditTrainigRecord";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "training_records",
        element: <Home />,
      },
      {
        path: "training_records/post",
        element: <PostTrainingRecord />,
      },
      {
        path: "training_records/:recordId",
        element: <TraingRecordDetail />,
      },
      {
        path: "training_records/:recordId/edit",
        element: <EditTrainingRecord />,
      },
    ],
  },
]);

export default router;
